// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-ciekawostki-js": () => import("./../../../src/pages/ciekawostki.js" /* webpackChunkName: "component---src-pages-ciekawostki-js" */),
  "component---src-pages-deklaracja-dostepnosci-js": () => import("./../../../src/pages/deklaracja-dostepnosci.js" /* webpackChunkName: "component---src-pages-deklaracja-dostepnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moje-trasy-[id]-js": () => import("./../../../src/pages/moje-trasy/[id].js" /* webpackChunkName: "component---src-pages-moje-trasy-[id]-js" */),
  "component---src-pages-moje-trasy-index-js": () => import("./../../../src/pages/moje-trasy/index.js" /* webpackChunkName: "component---src-pages-moje-trasy-index-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-pomoc-js": () => import("./../../../src/pages/pomoc.js" /* webpackChunkName: "component---src-pages-pomoc-js" */),
  "component---src-pages-powiadomienia-js": () => import("./../../../src/pages/powiadomienia.js" /* webpackChunkName: "component---src-pages-powiadomienia-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-sandbox-js": () => import("./../../../src/pages/sandbox.js" /* webpackChunkName: "component---src-pages-sandbox-js" */),
  "component---src-pages-trasy-[id]-js": () => import("./../../../src/pages/trasy/[id].js" /* webpackChunkName: "component---src-pages-trasy-[id]-js" */),
  "component---src-pages-trasy-index-js": () => import("./../../../src/pages/trasy/index.js" /* webpackChunkName: "component---src-pages-trasy-index-js" */),
  "component---src-pages-wydarzenia-js": () => import("./../../../src/pages/wydarzenia.js" /* webpackChunkName: "component---src-pages-wydarzenia-js" */)
}

